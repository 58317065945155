@keyframes moveUpFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slowlyMoveUpAndFadeLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  50% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes reallySlowlyMoveRightAndFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  50% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes reallySlowlyMoveUpAndFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
