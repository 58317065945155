h1 {
  font-family: "DM Serif", serif;
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
}

.left-section {
  flex: 1;
  position: relative;
  /* overflow: hidden; */
}

.right-section {
  flex: 1.618;
}

.links {
  position: absolute;
  right: calc(var(--spacing) * 2);
  bottom: calc(var(--spacing) * 2);
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column-reverse;
  }

  .links {
    display: none;
  }
}
