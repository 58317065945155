.social-links {
  display: flex;
  gap: calc(var(--spacing) * 2);
  animation: slowlyMoveUpAndFadeLeft 1s ease;
}

.social-links img {
  width: 44px;
  height: auto;
}
