.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--spacing);
  text-align: center;
  transition: font-size 0.5s ease;
  animation: moveUpFadeIn 1s ease;
}

.content-links {
  display: none;
}

.name-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.name-title p {
  white-space: nowrap;
  margin: 0;
  transition: font-size 0.5s ease;
}

.name {
  color: var(--primary);
  font-family: "DM Serif", serif;
  font-size: 3rem;
}

.title {
  color: var(--primary-light);
  font-family: "DM Sans", sans-serif;
  font-size: 2rem;
  margin-top: 0;
}

.skills {
  color: var(--grey);
  font-size: 1.2rem;
  transition: font-size 0.5s ease;
  margin-top: var(--spacing);
}


@media (max-width: 1100px) {
  .name {
    font-size: 2.8rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .skills {
    font-size: 1rem;
  }
}

@media (max-width: 1000px) {
  .name {
    font-size: 3rem;
    margin-right: unset;
    margin-bottom: 0;
  }

  .title {
    font-size: 2rem;
    position: unset;
    bottom: unset;
    margin-top: 0;
  }

  .skills {
    font-size: 1rem;
  }

  .content-links {
    margin-top: calc(var(--spacing) * 2);
    display: block;
  }
}
@media (max-width: 600px) {
  .name {
    font-size: 2.2rem;
  }

  .title {
    font-size: 1.2rem;
  }

  .skills {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .skills {
    font-size: 0.7rem;
  }
}
