.marble-ocean {
  position: absolute;
  right: 0;
  height: 110%;
  transform: rotate(-30deg);
  transition: transform 1s ease;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, var(--background), transparent);
}

.text-logo {
  position: absolute;
  bottom: calc(var(--spacing) * 2);
  left: calc(var(--spacing) * 2);
  animation: reallySlowlyMoveRightAndFadeIn 2s ease;
}

@media (max-width: 1000px) {
  .marble-ocean {
    right: auto;
    top: 0;
    transform: scale(200%) translate(0, 25%) rotate(200deg);
  }

  .gradient-overlay {
    background: linear-gradient(to top, var(--background), transparent);
  }

  .text-logo {
    left: 0;
    right: 0;
    margin: auto;
    animation: reallySlowlyMoveUpAndFadeIn 2s ease;
  }
}
